<template>
    <Modal class="content-type-selection" @close="closeForm()" :opened="opened">
        <template v-slot:header>
            {{ $t('cms.select_template')}}
        </template>
        
        <template v-slot:body>
            <LoadedContent class="types" :loaded="isLoaded" :error="isError">
                <div 
                    v-for="(type,index) in types" 
                    :key="index" class="type" 
                    :class="{selected: content.typeCode === type.item_key}" 
                    :title="type.description"
                    @click="selectType(type)"
                >
                    <img :src="getImage(type)" :alt="$t('cms.template_preview')" >
                    <div>
                        {{ type.name }}
                    </div>
                </div>
            </LoadedContent>
        </template>
    </Modal>
</template>

<script>
import Modal from "@common/components/Modal";
import LoadedContent from '@common/components/base/LoadedContent';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
    name: "ContentTypeSelection",
    components: {
        Modal,
        LoadedContent
    },
    emits: ['close','updateValues'],
    props: {
        opened: Boolean,
        content: {
            type: Object,
            default: () => {}
        }
    },
    setup(props,context){
        const store = useStore();

        const types = computed(() => store.getters['content_type/getItems']);
        
        const isLoaded = computed(() => store.getters["content_type/isLoaded"]);
        
        const isError = computed(() => store.getters["content_type/isError"]);
        
        const images = require.context('@admin/assets/content_templates/', true, /\.svg$/)

        const getImage = (type) => {
            if(type.image){
                return images('./'+type.image);
            }
            return images('./'+'default/no-image.svg');
        }

        const selectType = (type) => {
            context.emit('updateValues',{typeCode: type.item_key,type});
            context.emit('close');
        }
        
        const closeForm = () => {
            context.emit('close');
        }

        return {
            types,
            getImage,
            closeForm,
            selectType,
            isLoaded,
            isError
        }

    }
}
</script>

<style scoped>
.types{
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row;
}
.type{
    margin: 0.5rem;
    padding: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: 700;
}
.type>img{
    flex-grow: 1;
}
.type:hover{
    background: #e9fff3;
}
.selected{
    outline: 3px solid #177542;
    background: #e9fff3;
}

</style>